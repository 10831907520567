<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <b-button variant="success" @click="openModal">ثبت ویژگی</b-button>
      <b-card title="ویژگی‌ها" class="mt-3">
        <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
      size="sm"><i class="fa fa-bars"></i></b-button>
    <b-collapse id="filterBox" class="my-2">
      <b-card>
        <form @submit.prevent="searchItems()" id="search-item">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="title">اسم</label>
                <b-form-input id="title" v-model="searchParams.name"></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status" >
                      <b-form-select-option value="1">فعال</b-form-select-option>
                      <b-form-select-option value="0">غیرفعال</b-form-select-option>
                      <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
              </b-form-group>
          </div>
          <div class="col-md-3">
              <b-form-group label="عمومی" label-for="public">
                  <b-form-select id="public" v-model="searchParams.public">
                      <b-form-select-option value="1">عمومی</b-form-select-option>
                      <b-form-select-option value="0">غیر عمومی</b-form-select-option>
                      <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
              </b-form-group>
          </div>
          <div class="col-md-3">
              <b-form-group label="الزامی" label-for="required">
                  <b-form-select id="required" v-model="searchParams.required">
                      <b-form-select-option value="1">الزامی</b-form-select-option>
                      <b-form-select-option value="0">غیر الزامی</b-form-select-option>
                      <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
              </b-form-group>
          </div>
          </div>
          <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
            جو</button>
        </form>
      </b-card>
    </b-collapse>
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(type)="data">
              <span v-if="data.item.type == 'text'">متنی (کوتاه)</span>
              <span v-else-if="data.item.type == 'textarea'">متنی (چند خطی)</span>
              <span v-else-if="data.item.type == 'select'">انتخابی (تک مقدار)</span>
              <span v-else-if="data.item.type == 'multi_select'">انتخابی (چند مقدار)</span>
          </template>
          <template v-slot:cell(public)="data">
              <span class="pr-2" v-if="data.item.public == '1'"><i class="fas fa-check text-success"></i></span>
          </template>
          <template v-slot:cell(required)="data">
              <span class="pr-2" v-if="data.item.required == '1'"><i class="fas fa-check text-success"></i></span>
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
            <span class="badge badge-light" v-else>غیرفعال</span>
          </template>

          <template v-slot:cell(edit)="data">
              <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
      </pagination>
      <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
      </pagination>

      <b-modal id="new-modal" title="ثبت ویژگی" :size="(newItems.type == 'select' || newItems.type == 'multi_select') ? 'lg' : 'md'" hide-footer>
        <div>
          <form  @submit.prevent="newItem" id="new-item">
            <div class="row">
              <div :class="(newItems.type == 'select' || newItems.type == 'multi_select') ? 'col-6' : 'col-12'">
                <b-form-group >
                  <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-input id="name" name="name" data-required :disabled="disabled"></b-form-input>
                </b-form-group>

                <b-form-group >
                  <label for="type"> نوع <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <select name="type" id="type" class="form-control" v-model="newItems.type" data-required :disabled="disabled">
                    <option value="text">متنی (کوتاه)</option>
                    <option value="textarea">متنی (چند خطی)</option>
                    <option value="select">انتخابی (تک مقدار)</option>
                    <option value="multi_select">انتخابی (چند مقدار)</option>
                  </select>
                </b-form-group>

                <b-row>
                  <b-col>
                    <div class="form-group">
                      <label class="custom-switch">
                          <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status" :disabled="disabled">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">وضعیت</span>
                      </label>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="custom-switch">
                          <input type="checkbox" name="public" class="custom-switch-input" :disabled="disabled">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">ویژگی عمومی</span>
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <div class="form-group">
                  <label class="custom-switch">
                      <input type="checkbox" name="required" class="custom-switch-input" :disabled="disabled">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">الزامی </span>
                  </label>
                </div>

              </div>
              <div class="col-6" v-if="newItems.type == 'select' || newItems.type == 'multi_select'">
                <b-form-group label="مقدارها" label-for="type-value">
                  <div class="row" v-for="(item, index) in newItems.contents" :key="item.id">
                    <div class="col-10">
                      <input class="form-control mb-2" id="type-value" v-model="newItems.contents[index].value" name="contents[]" :disabled="disabled">
                    </div>
                    <div class="col-1">
                      <button class="btn btn-transparent" type="button" @click="deleteTypeValue(newItems, index)">
                        <i class="fa fa-close no-remove text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <button class="btn btn-info" type="button" @click="addTypeValue(newItems)">
                    <i class="fa fa-plus"></i>
                  </button>
                </b-form-group>
              </div>
            </div>

            <div class="clearfix">
              <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
              <b-button class="float-left" variant="white" type="button" @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
            </div>
          </form>
        </div>
      </b-modal>

      <b-modal id="edit-modal" title="ویرایش ویژگی" :size="(edit.type == 'select' || edit.type == 'multi_select') ? 'lg' : 'md'" hide-footer>
        <div v-if="edit.id">
          <form @submit.prevent="itemEdited" id="edit-item">
            <div class="row">
              <div :class="(edit.type == 'select' || edit.type == 'multi_select') ? 'col-6' : 'col-12'">
                <b-form-group >
                  <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-input id="name" name="name" v-model="edit.name" data-required></b-form-input>
                </b-form-group>

                <b-form-group >
                  <label for="type"> نوع <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <select name="type" id="type" class="form-control" v-model="edit.type" data-required>
                    <option value="text">متنی (کوتاه)</option>
                    <option value="textarea">متنی (چند خطی)</option>
                    <option value="select">انتخابی (تک مقدار)</option>
                    <option value="multi_select">انتخابی (چند مقدار)</option>
                  </select>
                </b-form-group>
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <label class="custom-switch">
                          <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">وضعیت</span>
                      </label>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="custom-switch">
                          <input type="checkbox" name="public" class="custom-switch-input" v-model="edit.public">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">ویژگی عمومی</span>
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <div class="form-group">
                  <label class="custom-switch">
                      <input type="checkbox" name="required" class="custom-switch-input" v-model="edit.required">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">الزامی</span>
                  </label>
                </div>

              </div>
              <div class="col-6" v-if="edit.type == 'select' || edit.type == 'multi_select'">
                <b-form-group label="مقدارها" label-for="type-value">
                  <div class="row" v-for="(item, index) in edit.contents" :key="item.id">
                    <div class="col-10">
                      <input class="form-control mb-2" id="type-value" v-model="edit.contents[index].value" name="contents[]">
                    </div>
                    <div class="col-1">
                      <button class="btn btn-transparent" type="button" @click="deleteTypeValue(edit, index)">
                        <i class="fa fa-close no-remove text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <button class="btn btn-info" type="button" @click="addTypeValue(edit)">
                    <i class="fa fa-plus"></i>
                  </button>
                </b-form-group>
              </div>
            </div>

            <div class="clearfix">
              <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
              <b-button class="float-left" variant="white" type="button" @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
            </div>
            <input type="hidden" value="PUT" name="_method">
          </form>
        </div>
      </b-modal>
    </div>
  </template>

<script>
import mixins from './mixins/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/attribute',
      title: 'ویژگی ها',
      newItems: {
        type: null,
        contents: [{}]
      },
      selected_country: null,
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'name', label: 'اسم' },
        { key: 'type', label: 'نوع' },
        { key: 'public', label: 'عمومی' },
        { key: 'required', label: 'الزامی' },
        { key: 'status', label: 'وضعیت' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' }
      ],
      selected_status: '1',
      selected_filter_status: '',
      selected_filter_public: '',
      selected_filter_required: '',
    }
  },
  methods: {
    addTypeValue (item) {
      item.contents.push({})
    },
    deleteTypeValue (item, index) {
      this.$root.$delete(item.contents, index)
    },
    openModal() {
      this.$root.$emit('bv::show::modal', 'new-modal')
      this.newItems = {
        type: null,
        contents: [{}]
      }
    }
  },
  created () {
    this.loadItems()
    this.searchParams = {
      name:'',
      status:'',
      required:'',
      public:''
    }
  }
}
</script>
